.filterContainer {
  /* border: 1px solid red; */
  /* margin-top: 50px;
  padding: 20px 12px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color: #000; */
}

tbody {
  cursor: auto !important;
}

.form-check-input:checked {
  background-color: var(--cui-form-check-input-checked-bg-color, #0b488e);
  border-color: var(--cui-form-check-input-checked-border-color, #0b488e);
}

/* .form-check-input[type=checkbox]:indeterminate {
  background-color: #0b488e;
  border-color: #0b488e;
  background-image: var(--cui-form-check-input-indeterminate-bg-image, url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27rgba%28255, 255, 255, 0.87%29%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10h8%27/%3e%3c/svg%3e"));
} */



input.form-check-input {
  /* display:; */
}

/* .form-check-input:checked[type=checkbox] {

  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
  /* display: none; */
/* }  */

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  text-align: left;
}

.table>:not(:last-child)>:last-child>* {
  border-bottom-color: rgb(255, 255, 255);
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #002663 !important;
  border-color: #002663 !important;
}

a#buttonbg {

  padding: 8px 16px;
  border-radius: 3px;
  color: #fff;
  font-weight: 600;
  border: 1px solid #0b488e !important;
  background-color: #0b488e !important;
  font-size: 15px;
}

.table-danger {
  --cui-table-color: rgba(44, 56, 74, 0.95);
  --cui-table-bg: #0b488e !important;
  --cui-table-border-color: rgba(231, 206, 208, 0.995);
  --cui-table-striped-bg: rgba(241, 214, 214, 0.9975);
  --cui-table-striped-color: rgba(44, 56, 74, 0.95);
  --cui-table-active-bg: rgba(231, 206, 208, 0.995);
  --cui-table-active-color: rgba(44, 56, 74, 0.95);
  --cui-table-hover-bg: rgba(236, 210, 211, 0.99625);
  --cui-table-hover-color: rgba(44, 56, 74, 0.95);
  color: var(--cui-table-color);
  border-color: var(--cui-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  color: #FFF !important;
  --bs-table-bg: #0b488e !important;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #0b488e;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.updateBtn {
  /* margin-right: 2px; */
  background: none!important;
  /* border-color: #B8C7D8 !important; */
  /* padding: 0px !important; */
  border: none!important;
}

.updateBtn1 {
  margin-top: 5px;
  margin-right: 2px;
  background: #4285f4 !important;
  border-color: #4285f4 !important;
  padding: 2px 6px !important;
}

.deleteBtn {
  /* background-color: #fff !important; */
  background: none!important;
  border-color: #B8C7D8 !important;
  padding: 3px 6px !important;
  border: none!important;
}

.pagination {
  width: max-content !important;
  border-radius: 5px !important;
}

.deleteBtn1 {
  margin-top: 5px;
  background-color: #ea4335 !important;
  border-color: #ea4335 !important;
  padding: 2px 6px !important;
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #86d1ee !important;
  --bs-table-border-color: #bacbe6;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.container {
  overflow: auto;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {

  .btn-transparent {
    margin-top: 40px;
    margin-left: 5px;
  }

  ul.pagination.justify-content-start {
    justify-content: center !important;
    display: flex;
  }
}

/*
@media screen and (min-width: 481px) and (max-width: 768px)
and (-webkit-min-device-pixel-ratio: 2)
{

  .btn-transparent {

      margin-top: 40px;
      margin-left: 5px;
  }
  ul.pagination.justify-content-start {
      justify-content: center !important;
      display: flex;
  }

} */

/*
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 1) {


  .btn-transparent {
      margin-top: 0px;
      margin-left: 0px;
  }
  ul.pagination.justify-content-start {
      justify-content: flex-start !important;
      display: flex;
  }

} */
